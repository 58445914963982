import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend, { HttpBackendOptions } from 'i18next-http-backend';
const userLocale =
  navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.Í
  .use(initReactI18next)
  .use(Backend)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init<HttpBackendOptions>({
    lng: userLocale.split('-')[0].toLocaleLowerCase(),
    // lng: 'fr',
    // fallbackLng: userLocale.split('-')[0].toLocaleLowerCase(),
    interpolation: {
      escapeValue: false, // React already escapes values by default
    },
    supportedLngs: ['fr', 'en', 'es'],
    load: 'currentOnly',
    returnEmptyString: true,
    // keySeparator: false,
  });

export default i18n;
